<template>
  <div
    class="builder-block brick container min-h-fit flex-1 p-2"
    v-if="options"
    ref="container"
    :class="{ 'overflow-hidden': context.isLast, 'is-overflow': context.isLast && isOverflow }"
  >
    <div class="flex min-h-full flex-col gap-1" ref="element">
      <div class="heading1" v-if="options.title" v-html="translate(options.title)"></div>
      <div class="heading2" v-html="translate(options.subtitle)"></div>
      <div>
        <!-- <div>{{ context.layout }}</div> -->
        <slot></slot>
      </div>
      <div class="disclaimer" v-if="options.disclaimer || (blockFootnotes && blockFootnotes.length)">
        <div v-html="translate(options.disclaimer)"></div>
        <div v-if="blockFootnotes">
          <span v-for="(footnote, idx) in blockFootnotes" :key="idx">
            <sup>{{ footnote.index }}</sup>
            <span v-html="footnote.value"></span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { ResolvedBuilderOptions } from '../composables/builderOptions'
import useTranslations from '../composables/translations'
import useOverflow from '../composables/overflow'
const emit = defineEmits(['overflow'])

// TODO: have to put this override here else type checks fail
interface BuilderBlockProps {
  data?: any[] | Record<string, any[]>
  options: ResolvedBuilderOptions
  context: Record<string, any>
}
const container = ref<HTMLElement>()
const element = ref<HTMLElement>()
const props = defineProps<BuilderBlockProps>()
const { translate, blockFootnotes } = useTranslations(props)
function onOverflow(value: boolean) {
  if (props.context.isLast) {
    emit('overflow', { isOverflow: value })
  }
}

const { isOverflow, initOverflow } = useOverflow(element, container, onOverflow)
onMounted(() => {
  initOverflow()
})

defineExpose({ element, container })
</script>
<script lang="ts">
export default {
  styles: { heading1: '', heading2: '', disclaimer: '', container: '' },
  story: {
    props: {
      options: {
        title: 'Heading 1',
        subtitle: 'Heading 2',
        disclaimer: 'Disclaimer',
      },
      context: {
        translations: {},
      },
    },
    slot: `<div class="h-36">Content</div>`,
  },
}
</script>
