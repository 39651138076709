<template>
  <popup :show="show">
    <template #header>
      {{
        props.templateToDuplicateId
          ? $root.t.create_template_popup.duplicate_title
          : $root.t.create_template_popup.create_title
      }}
    </template>
    <template #content>
      <div class="form">
        <div class="column">
          <label>{{ $root.t.create_template_popup.template_name_label }}</label>
          <input
            type="text"
            style="width: 100%"
            v-model="createTemplateForm.name"
            :placeholder="$root.t.create_template_popup.template_name_placeholder"
          />
        </div>
        <div class="column">
          <label>{{ $root.t.create_template_popup.data_report_label }}</label>
          <select v-model="createTemplateForm.dataReportId">
            <option :value="null" disabled>{{ $root.t.create_template_popup.data_report_placeholder }}</option>
            <option v-for="report in dataReports" :value="report.id" :key="report.id">
              {{ report.name }}
            </option>
          </select>
        </div>
      </div>
    </template>
    <template #action>
      <button class="secondary-action" @click="closePopup">{{ $root.t.cancel }}</button>
      <button
        class="main-action"
        @click="createPdf"
        :disabled="!createTemplateForm.name || !createTemplateForm.dataReportId"
      >
        {{ $root.t.create_template_popup.create }}
      </button>
    </template>
  </popup>
</template>

<script setup lang="ts">
import { ref, defineEmits, onMounted, watch } from 'vue'
import dataReportService from '@100-m/hauru/src/services/DataReportService'
import popup from '@100-m/hauru/src/components/ui/popup.vue'
import templateService, { Template } from '@100-m/hauru/src/services/TemplateService'

const props = defineProps<{
  show: boolean
  templateToDuplicateId: number
}>()

const emit = defineEmits(['create-template', 'close'])

const dataReports = ref([])
const templateToDuplicate = ref<Template | null>(null)

const createTemplateForm = ref<{ name: string | null; dataReportId: number | null }>({
  name: null,
  dataReportId: null,
})

const fetchData = async () => {
  const _dataReports = await dataReportService.list()
  dataReports.value = _dataReports.filter((dataReport: any) => typeof dataReport.id === 'number')

  if (props.templateToDuplicateId) {
    templateToDuplicate.value = await templateService.getById({ id: props.templateToDuplicateId })

    createTemplateForm.value = {
      name: 'Copy - ' + templateToDuplicate.value.name,
      dataReportId: templateToDuplicate.value.dataReportId,
    }
  }
}

onMounted(fetchData)

watch(
  () => props.templateToDuplicateId,
  async (newVal, oldVal) => {
    if (newVal !== oldVal) {
      await fetchData()
    }
  },
)

const closePopup = () => {
  templateToDuplicate.value = null

  emit('close')
}

const createPdf = () => {
  emit('create-template', {
    ...(templateToDuplicate.value?.layout && { layout: templateToDuplicate.value.layout }),
    ...createTemplateForm.value,
  })

  templateToDuplicate.value = null
}
</script>

<style scoped>
.form {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 24px;
}
</style>
