<style scoped>
h1 .search-filters {
  display: flex;
  flex: 1;
  margin-left: 10px;
}
h1 .search-filters .autocomplete {
  min-width: 90% !important;
}
.cell-url .link {
  width: calc(100% - 30px);
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 0 0 0 8px;
}
.cell-url .ghost {
  position: absolute;
  right: 0;
  max-width: 30px;
}

.data-report-product-tag {
  padding: 2px 5px;
  margin-left: 8px;
  border-radius: 3px;
  color: black;
  border: 1px solid black;
  font-size: 8px;
}

.mr2 {
  margin-right: 8px;
}

.hidden {
  display: none;
}

.block .spr.spreadsheet-wrapper {
  max-height: 75vh;
}

.isWarning {
  color: #e17315;
}
.isOk {
  color: green;
}

.table-bottom-actions {
  display: flex;
  gap: 8px;
}

.table-bottom-actions :nth-last-child(1) {
  margin-left: auto;
}
</style>

<template>
  <transition @leave="leave">
    <loader v-if="loading" />
  </transition>
  <h1>
    <div class="row">
      <span style="margin-right: 8px">{{ t[$root.screen.path] }}</span>
      <badge :count="queries.length" />
    </div>
    <div class="search-filters">
      <autocomplete
        class="right"
        :data="autocompleteData"
        :options="{ placeholder: t.search }"
        :modelValue="activated_filters"
        @update:modelValue="autocomplete_input"
        @keyup.native="search = $event.target.value"
        @focusout.native="search = ''"
      />
    </div>
  </h1>
  <subtitle style="font-style: italic; margin-top: -5px">
    {{ t['client-edition-data-reports-subtitle'] }}
  </subtitle>
  <div class="expand data-reports block">
    <spreadsheet
      class="stripped"
      :data="filtered_queries"
      :options="{ columns: ['name', 'specialtyName', 'url', 'usage', 'actions'], editable: false }"
    >
      <template v-slot:cell-name="s">
        <div>
          {{ s.line.name }}
          <span v-if="isBuiltInDr(s.line.id)" :tt="t.built_in_tag" class="data-report-product-tag">BUILT-IN</span>
        </div>
      </template>
      <template v-slot:cell-url="s">
        <a class="link" :href="s.line.url" target="_blank">
          {{ s.line.url.replace(/.token=.*/, '') }}
        </a>
        <button class="ghost" :tt="t.copy" @click="copy(s.line.url)">
          <svg-icon name="ic_file_copy" />
        </button>
      </template>
      <template v-slot:cell-actions="s">
        <div class="row">
          <button
            class="ghost"
            :tt="isBuiltInDr(s.line.id) ? t.view : t.edit"
            @click="$router.push({ path: $root.appath + 'data-report', query: { id: s.line.id } })"
          >
            <svg-icon :name="isBuiltInDr(s.line.id) ? 'pt-icon-eye-open' : 'pt-icon-edit'" />
          </button>
          <button
            class="ghost"
            :tt="t.duplicate"
            @click="openDuplicateDataReport(s.line.id)"
            v-if="!isBuiltInDr(s.line.id)"
          >
            <svg-icon name="ic_file_copy" />
          </button>
          <button class="ghost" :tt="t.export" @click="exportDr(s.line)" v-if="!isBuiltInDr(s.line.id)">
            <svg-icon name="pt-icon-export" />
          </button>
          <button
            class="ghost"
            :tt="t.run"
            @click="$router.push({ path: $root.appath + 'data-report', query: { id: s.line.id, tab: 'run' } })"
          >
            <svg-icon name="pt-icon-play" />
          </button>
          <button class="ghost" :tt="t.delete" @click="confirmRemoveId = s.line.id" v-if="s.line.usage === 0">
            <svg-icon name="pt-icon-trash" />
          </button>
          <button class="ghost" :tt="t.open_studio" @click="redirectToStudio(s.line)">
            <svg-icon name="pt-icon-graph" />
          </button>
        </div>
      </template>
    </spreadsheet>
    <div class="row table-bottom-actions">
      <button class="primary" @click="createDataReport">
        {{ t.create_data_report }}
      </button>
      <span>
        <button class="primary" @click="triggerFileInput">
          {{ t.import_data_report }}
        </button>
        <input class="hidden" type="file" ref="fileInput" accept="application/json" @change="importDr" />
      </span>
      <button class="primary" @click="redirectToStudio">
        {{ t.open_studio }}
      </button>
    </div>
  </div>

  <popup :show="confirmRemoveId">
    <template v-slot:header>
      {{ t['confirmation_remove'] }}
    </template>
    <template v-slot:content>
      {{ t['text_remove'] }}
    </template>
    <template v-slot:action>
      <button class="secondary-action" @click="confirmRemoveId = null">
        {{ t['confirmation_btn_close'] }}
      </button>
      <button class="main-action" @click="deleteDr(confirmRemoveId)">
        {{ t['confirmation_btn_delete'] }}
      </button>
    </template>
  </popup>

  <duplicate-data-report-popup
    :show="showDuplicateDataReport"
    :dataReportToDuplicateId="dataReportToDuplicateId"
    @duplicate-data-report="handleDuplicateDataReport"
    @close="handleCloseDuplicateDataReport"
  />
</template>

<script>
import dataReportService from '@100-m/hauru/src/services/DataReportService'
import templateService from '../../../services/TemplateService'
import { readJSONFile } from '../../builder/lib/assetManagement'
import { generateStudioUrl } from '../../../utils/generateStudioUrl'

export const additions = { icon: 'pt-icon-graph' }

export default {
  data() {
    return {
      config, // so that it is also available from the vue template
      loading: true,
      queries: [],
      search: '',
      confirmRemoveId: null,
      showDuplicateDataReport: false,
      dataReportToDuplicateId: null,
    }
  },
  computed: {
    filtered_queries() {
      const { domain, ...filters } = $root.filters
      return this.queries.filter(f => Object.entries(filters).every(([k, vs]) => vs.some(v => f[k] === v)))
    },

    activated_filters() {
      const params = this.$route.query
      const activeFilters = params.map(filter_value => filter_value.split('|'))
      const filters = []
      Object.entries(activeFilters).forEach(([filterName, filterValues]) => {
        if (filterName === 'year') return
        filterValues.forEach(value => {
          filters.push(this.formatFilterData(filterName, value))
        })
      })
      return filters
    },

    autocompleteData() {
      const filterCriteria = ['name']
      return this.queries.reduce((groupedQueries, q) => {
        filterCriteria.forEach(criterium => {
          if (typeof q[criterium] === 'string') {
            const filterValue = q[criterium].split('.')[0]
            groupedQueries[criterium] = groupedQueries[criterium] || {}
            groupedQueries[criterium][filterValue] = filterValue
          }
        })
        return groupedQueries
      }, {})
    },
  },

  async mounted() {
    this.$router.replace(this.$route.fullPath.split('?')[0])
    await this.init()
  },

  methods: {
    isBuiltInDr(id) {
      return isNaN(parseInt(id))
    },
    triggerFileInput() {
      this.$refs.fileInput.click()
    },
    async init() {
      this.loading = true
      this.queries = await this.getQueries()
      this.loading = false
    },
    autocomplete_input(event) {
      const events = event.map(v => v.split('.'))
      const groupedEvents = events.group('0')
      const selected_filters = groupedEvents.map(g => g.map('1').join('|'))
      const new_filter_selected = this.$route.query.filter((v, k) => !this.$route.query.keys().includes(k))
      const query = { ...selected_filters, ...new_filter_selected }
      this.$router.push({ query })
    },
    formatFilterData(name, value) {
      return name + '.' + value
    },
    async getQueries() {
      const r = await dataReportService.list()
      const templates = await templateService.findMany()
      return r.map(q => {
        const varUrl = `?token=${$root?.profile?.idToken}&runVariables=${encodeURIComponent(JSON.stringify(this.variables))}`

        // LFRA CASE: graphqlEndpoint is the legacy api
        const baseUrl = config.graphqlEndpoint.match(/http:\/\/|https:\/\/|localhost/)
          ? ''
          : `${window.location.protocol}//${window.location.host}`
        // TODO: Remove hardcoded builder-context
        // Add to protect against deletion of a data report that is used by product [builder-context])
        return {
          ...q,
          url: `${baseUrl}${config.graphqlEndpoint}/dr/${q.id}/run${varUrl}`, // actually here we use the REST 'dr' route of our GraphQL API...
          usage: Number.isInteger(q.id) ? templates.filter(t => t.dataReportId === q.id).length : 1,
        }
      })
    },
    openDuplicateDataReport(dataReportId) {
      this.dataReportToDuplicateId = dataReportId
      this.showDuplicateDataReport = true
    },
    createDataReport() {
      this.$router.push({ path: $root.appath + 'data-report', query: { new: true } })
    },
    async handleDuplicateDataReport(dataReportData) {
      if (!dataReportData.name) {
        return $root.toast({ description: $root.t.missing_mandatory_fields, type: 'error', timeout: 5000 })
      }

      try {
        const createdDataReport = await dataReportService.create({
          name: dataReportData.name,
          query: dataReportData.query,
          variables: dataReportData.variables ? dataReportData.variables : {},
          specialtyName: dataReportData.specialtyName,
        })

        this.$router.push({ path: $root.appath + 'data-report', query: { id: createdDataReport.id } })
      } catch (error) {
        if (!error.message) {
          return
        }

        if (error.message.includes('already exists')) {
          return $root.toast({ description: $root.t.dataReportMustBeUnique, type: 'error', timeout: 5000 })
        }
        if (error.message.includes('must not be null')) {
          return $root.toast({ description: $root.t.invalid_values, type: 'error', timeout: 5000 })
        }
        $root.toast({ description: error.message, type: 'error', timeout: 5000 })
      }
    },
    handleCloseDuplicateDataReport() {
      this.dataReportToDuplicateId = null
      this.showDuplicateDataReport = false
    },
    async importDr(event) {
      const dataReportUpload = await readJSONFile(event)
      if (dataReportUpload.name && dataReportUpload.query) {
        const dataReportCurrent = await dataReportService.getByName(dataReportUpload.name)
        if (dataReportCurrent) {
          // Ask user if we override current dr
          if (confirm($root.t.ifOverrideDataReport)) {
            const updatedDataReport = await dataReportService.update(dataReportCurrent.id, {
              name: dataReportUpload.name,
              query: dataReportUpload.query,
              variables: dataReportUpload.variables || {},
            })
            $root.toast({ description: $root.t.dataReportUpdateSuccess, type: 'success', timeout: 5000 })
            this.$router.push({ path: $root.appath + 'data-report', query: { id: updatedDataReport.id } })
          }
        } else {
          const createdDataReport = await dataReportService.create({
            name: dataReportUpload.name,
            query: dataReportUpload.query,
            variables: dataReportUpload.variables || {},
            specialtyName: dataReportUpload.specialtyName || 'document by share class',
          })
          $root.toast({ description: $root.t.dataReportCreateSuccess, type: 'success', timeout: 5000 })
          this.$router.push({ path: $root.appath + 'data-report', query: { id: createdDataReport.id } })
        }
      } else {
        $root.toast({ description: $root.t.dataReportFormatError, type: 'error', timeout: 5000 })
      }
    },
    async exportDr(reportInfo) {
      const dataReport = await dataReportService.getById(reportInfo.id)
      const dataReportExport = {
        name: dataReport.name || '',
        query: dataReport.query || '',
        variables: dataReport.variables || {},
        specialtyName: dataReport.specialtyName || 'document by share class',
      }
      JSON.stringify(dataReportExport).download(
        `${$root.project}-${dataReport.name}-${new Date().format('YYYY-MM-DD.hhhmmm')}.json`,
      )
      $root.toast({ description: $root.t.dataReportDownloadSuccess, type: 'success', timeout: 5000 })
    },
    async deleteDr(id) {
      try {
        await dataReportService.remove(id)
      } catch (e) {
        $root.toast({ description: e.message, type: 'error', timeout: 5000 })
        return
      }
      this.queries.splice(
        this.queries.findIndex(q => q.id === id),
        1,
      )
      this.confirmRemoveId = null
      await this.init()
    },
    async copy(url) {
      await navigator.clipboard.writeText(url)
    },
    redirectToStudio({ query, variables } = {}) {
      const url = generateStudioUrl({ query, variables })
      return window.open(url, '_blank')
    },
  },
}
</script>
