import { urlJoin } from '../../utils.js'

export default class DockerFileSystem {
  #dockerURL
  #token

  constructor(config) {
    this.#dockerURL = config.endpoint
    this.#token = config.token
  }

  async upload({ buffer, filenameWithPath, isPrivateFile }) {
    const { filename, bucketPath } = this.splitFilenameToPathAndFilename(
      filenameWithPath,
      isPrivateFile
    )

    let formattedBuffer = buffer

    if (ArrayBuffer.isView(buffer)) {
      formattedBuffer = { type: 'Buffer', data: Array.from(buffer) }
    }

    const body = JSON.stringify({
      buffer: formattedBuffer,
      bucketPath,
      filename
    })

    const res = await fetch(urlJoin(this.#dockerURL, `filesystem`), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: this.#token
      },
      body
    })

    if (res.status !== 200) {
      console.log({ resStatus: res.status, resMessage: res.statusText })
    }

    return res.status === 200
  }

  async get({ filenameWithPath }) {
    const { filename, bucketPath } = this.splitFilenameToPathAndFilename(
      filenameWithPath,
      true
    )

    return (
      await fetch(
        urlJoin(this.#dockerURL, '/filesystem/', bucketPath, filename),
        {
          method: 'GET',
          headers: {
            authorization: this.#token
          }
        }
      )
    ).blob()
  }

  getPublicUrl({ filenameWithPath }) {
    const { filename, bucketPath } = this.splitFilenameToPathAndFilename(
      filenameWithPath,
      false
    )

    return urlJoin(this.#dockerURL, '/filesystem/', bucketPath, filename)
  }

  async deleteFile({ filenameWithPath, isPrivateFile }) {
    const { filename, bucketPath } = this.splitFilenameToPathAndFilename(
      filenameWithPath,
      isPrivateFile
    )
    const res = await fetch(urlJoin(this.#dockerURL, '/filesystem'), {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        authorization: this.#token
      },
      body: JSON.stringify({
        filename,
        bucketPath
      })
    })

    if (res.status !== 200) {
      console.log({ resStatus: res.status, resMessage: res.statusText })
    }

    return res.status === 200
  }

  async deleteFolder({ filenameWithPath, isPrivateFolder }) {
    const { filename, bucketPath } = this.splitFilenameToPathAndFilename(
      filenameWithPath,
      isPrivateFolder
    )

    const res = await fetch(urlJoin(this.#dockerURL, '/filesystem'), {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        authorization: this.#token
      },
      body: JSON.stringify({ path: `${bucketPath}/${filename}` })
    })

    if (res.status !== 200) {
      console.log({ resStatus: res.status, resMessage: res.statusText })
    }

    return res.status === 200
  }

  splitFilenameToPathAndFilename(filenameWithPath, isPrivateFile) {
    const filenameWithPathSplit = filenameWithPath
      .replaceAll(' ', '_')
      .replaceAll(':', '_')
      .split('/')
      .map((e) => encodeURIComponent(e))

    const filename = filenameWithPathSplit.pop()
    const bucketPath = `${
      isPrivateFile ? '' : 'public/'
    }${filenameWithPathSplit.join('/')}`

    return { filename, bucketPath }
  }
}
