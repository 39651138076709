<style>
.stylesheets .cell-format {
  flex: unset !important;
}
</style>

<template>
  <transition @leave="leave">
    <loader v-if="isLoading" />
  </transition>
  <div class="row">
    <h1 style="margin-right: 0">Stylesheets</h1>
    <badge :count="stylesheets.length" />
  </div>
  <subtitle style="font-style: italic; margin-top: -5px">{{ t['client-edition-stylesheet-subtitle'] }}</subtitle>
  <div class="expand block">
    <spreadsheet
      class="stripped expand nosort"
      :data="stylesheets"
      :options="{ columns: ['name', 'Last Update', 'usage', 'actions'], editable: false }"
    >
      <template v-slot:cell-last-update="{ column, line }">
        <div>{{ line.createdAt }}</div>
      </template>
      <template v-slot:cell-actions="{ column, line }">
        <div class="row">
          <button class="ghost" tt="View" @click="edit(line)" v-if="line.readOnly">
            <svg-icon name="pt-icon-eye-open" />
          </button>
          <button class="ghost" tt="Edit" @click="edit(line)" v-else>
            <svg-icon name="pt-icon-edit" />
          </button>
          <button class="ghost" tt="Duplicate" @click="openDuplicateStylesheet(line.id)">
            <svg-icon name="ic_file_copy" />
          </button>
          <button
            class="ghost"
            tt="Delete"
            @click="confirmRemoveId = line"
            v-if="true"
            :disabled="line.readOnly || line.usage"
          >
            <svg-icon name="pt-icon-trash" />
          </button>
        </div>
      </template>
    </spreadsheet>
    <div class="row">
      <button class="primary" @click="openCreateStylesheet">Create stylesheet</button>
    </div>

    <popup :show="confirmRemoveId">
      <template v-slot:header>
        {{ t['confirmation_remove'] }}
      </template>
      <template v-slot:content>
        {{ t['text_remove'] }}
      </template>
      <template v-slot:action>
        <button class="secondary-action" @click="confirmRemoveId = null">{{ t['confirmation_btn_close'] }}</button>
        <button class="main-action" @click="remove(confirmRemoveId)">{{ t['confirmation_btn_delete'] }}</button>
      </template>
    </popup>

    <create-stylesheet-popup
      :show="showCreateStylesheet"
      :stylesheetToDuplicateId="stylesheetToDuplicateId"
      @create-stylesheet="handleCreateStylesheet"
      @close="handleCloseCreateStylesheet"
    />
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import stylesheetService from '../../../services/StylesheetService'
import { merge } from '@100-m/hauru/src/utils/merge'
import { deleteFolder, uploadAssetImage, getAssetBlob } from '@100-m/hauru/src/applications/builder/lib/assetManagement'

const isLoading = ref(true)
const stylesheets = ref([])
const confirmRemoveId = ref(null)
const showCreateStylesheet = ref(false)
const stylesheetToDuplicateId = ref(null)

onMounted(async () => {
  stylesheets.value = await getStylesheets()
})

const getStylesheets = async () => {
  isLoading.value = true
  const stylesheets = await stylesheetService.findMany()
  isLoading.value = false
  return stylesheets
}

const openCreateStylesheet = async () => {
  showCreateStylesheet.value = true
}

const openDuplicateStylesheet = async stylesheetId => {
  stylesheetToDuplicateId.value = stylesheetId
  showCreateStylesheet.value = true
}

const handleCreateStylesheet = async stylesheetData => {
  if (!stylesheetData.name) {
    return $root.toast({ description: $root.t.missing_mandatory_fields, type: 'error', timeout: 5000 })
  }

  if (stylesheets.value.find(stylesheet => stylesheet.name === stylesheetData.name)) {
    return $root.toast({ description: $root.t.stylesheet_name_already_exists, type: 'error', timeout: 5000 })
  }

  const defaultStylesheet = {
    styles: {},
    palettes: [],
  }

  const isDuplicate = !!stylesheetData.id

  let logoBucketPath

  // If stylesheet to duplicate has logo, reupload it in the new folder
  if (isDuplicate && stylesheetData.logo) {
    const logoBlob = await getAssetBlob({ filenameWithPath: stylesheetData.logo, isPrivateFile: false })
    const fileExtension = stylesheetData.logo.split('.').pop()

    const filename = `logo-1.${fileExtension}`

    const { bucketPath } = await uploadAssetImage({
      arrayBuffer: await logoBlob.arrayBuffer(),
      name: filename,
      subFolder: `${stylesheetData.name}/logo`,
      isPrivateFile: false,
    })

    if (!bucketPath) {
      return $root.toast({ description: 'Error while uploading image', type: 'error', timeout: 5000 })
    }

    logoBucketPath = bucketPath
  }

  await stylesheetService.create({
    stylesheetInput: {
      ...(isDuplicate ? { ...stylesheetData } : merge(defaultStylesheet, config.defaultStylesheet)),
      ...(logoBucketPath && { logo: logoBucketPath }),
      name: stylesheetData.name,
    },
  })

  edit({ name: stylesheetData.name })
}

const handleCloseCreateStylesheet = () => {
  stylesheetToDuplicateId.value = null
  showCreateStylesheet.value = false
}

const edit = stylesheetInfo => {
  $root.$router.push(`/builder/stylesheet?stylesheetName=${stylesheetInfo.name}`)
}

const remove = async stylesheetInfo => {
  if (stylesheetInfo.readOnly) {
    return
  }

  confirmRemoveId.value = null

  await stylesheetService.deleteManyByName({ name: stylesheetInfo.name, limit: 1 })
  await deleteFolder({ filenameWithPath: `assets/images/${stylesheetInfo.name}`, isPrivateFolder: false })

  stylesheets.value = await getStylesheets()
}
</script>

<script>
export default {
  icon: 'ic_paint',
}
</script>
