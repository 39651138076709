<style>
.pdf-esg-standard .row .column:nth-child(1) {
  display: flex;
  min-width: 260px;
  max-width: 260px;
  overflow: hidden;
}
.pdf-esg-standard .row .column:nth-child(2) {
  display: flex;
  flex-grow: 1;
  min-width: unset !important;
  max-width: unset !important;
}
.pdf-esg-standard .row .column:nth-child(3) {
  display: flex;
  flex-grow: 1;
  min-width: unset !important;
  max-width: unset !important;
}
.pdf-esg-standard .row .pdf-horizontalbar-icon.esg_impact_positif {
  justify-content: center;
  align-items: end;
}
.pdf-esg-standard .row .pdf-horizontalbar-icon.esg_impact_positif > div.row {
  min-width: 100%;
}
.pdf .carbon_intensity .bar-chart .rect {
  --size: 3;
}
.pdf .carbon_intensity .bar-chart .rect.benchmark {
  background: var(--secondary) !important;
}
.pdf .carbon_intensity .bar-chart .row {
  padding: 8px 0px;
}

.sfdr-block {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.sfdr-block .row {
  width: 200px;
  background: var(--background);
  border-radius: 8px;
  padding: 2px;
  margin: 2px 0;
}
.sfdr-block .row .sfdr-text {
  display: flex;
  flex: 2;
  align-items: center;
  text-align: center;
  font-weight: 600;
}
.sfdr-block .row .sfdr-value {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 12px;
}

.pdf .block-data_impact {
  min-width: 100%;
  height: 300px;
}
.pdf-esg-standard .subtitle.row {
  margin-left: 8px;
}

.pdf .brick.empty {
  display: flex;
  flex: 1;
}
.pdf .carbon_footprint {
  display: flex;
  flex: 1;
}

.pdf-esg-standard .top5_esg .pdf-table .cell:not(.c1),
.pdf-esg-standard .worst5_esg .pdf-table .cell:not(.c1) {
  width: 57px;
}
.lang-es .pdf-esg-standard .top5_esg .pdf-table .cell,
.lang-es .pdf-esg-standard .worst5_esg .pdf-table .cell,
.lang-it .pdf-esg-standard .top5_esg .pdf-table .cell,
.lang-it .pdf-esg-standard .worst5_esg .pdf-table .cell {
  padding: 4px 0px 4px 0px;
}
.sfdr-block.odd {
  margin-top: 20px;
}
.disclaimer.odd {
  margin-top: 5px;
  max-width: 200px;
  text-align: justify;
}
.pdf-esg-standard .temperature {
  width: 180px;
}
</style>

<template lang="pug">
.pdf-esg-standard
  .subtitle.row(v-if="data.share.characteristics.benchmark_esg") {{ (t['univers_definition'] || 'univers_definition') + ': ' + t[data.share.characteristics.benchmark_esg] }}
  .row
    .column
      brick.no-bold.center-value(:title="data.share.characteristics.esg_coverage_table" :subtitle="$root.t[`${data.share.characteristics.esg_coverage_table}_subtitle`] || 'subtitle_esg_coverage'" :data="data.tables.esg_coverage_table")
        div.source(v-if="$root.lang.includes('be')") {{ t.source + ' ' + t[data.share.characteristics.management_company] }}
    // to keep for now, client unsure of the deletion
    //.column
    //  brick.no-bold.main_table_esg(title="top5_esg" :data="data.esg.main_lines_table_esg.desc" :options="{ limit: 6 }" subtitle="subtitle_main_lines_esg")
    //    div.source(v-if="$root.lang.includes('be')") {{ t.source + ' ' + t[data.share.characteristics.management_company] }}
    .column
      brick.esg_vbar(title="esg_distribution" type="vbar" :data="data.esg.esg_distribution" :options="{ format: '.2%', sort: 'key', variant: 'clustered-bar'}"  subtitle="subtitle_distribution_esg")
        div.source(v-if="$root.lang.includes('be')") {{ t.source + ' ' + t[data.share.characteristics.management_company] }}
    // to keep for now, client unsure of the deletion
    //.column
    //  brick.no-bold.main_table_esg(title="worst5_esg" :data="data.esg.main_lines_table_esg.asc" :options="{ limit: 6 }" subtitle="subtitle_main_lines_esg")
    //    div.source(v-if="$root.lang.includes('be')") {{ t.source + ' ' + t[data.share.characteristics.management_company] }}
    .column.temperature(v-if="data.share.characteristics.display_esg_temperature && (typeof(data.esg.temperature_data.values.fund) === 'number' || typeof(data.esg.temperature_data.values.benchmark) === 'number')")
      brick(title="temperatures")
      pdf-thermometer(:data="data.esg.temperature_data")
      .disclaimer(v-if="data.esg.temperature_data.coverage.fund") {{ t['temperature_coverage_fund'] || 'temperature_coverage_fund' }}: {{format('.2%')(data.esg.temperature_data.coverage.fund)}}
      .disclaimer(v-if="data.esg.temperature_data.coverage.benchmark") {{ t['temperature_coverage_benchmark'] || 'temperature_coverage_benchmark' }}: {{format('.2%')(data.esg.temperature_data.coverage.benchmark)}}
    .column(v-else :style="{display: 'none'}")
  .row(v-if="!data.share.characteristics.hide_carbon")
    .column(v-if="data.esg.carbon_footprint.length")
      .row
        brick.no-bold.main_table(title="carbon_footprint" subtitle="subtitle_carbon_footprint")
          pdf-horizontalbar-icon(:data="data.esg.carbon_footprint" :options="{ format: '.0f'}")
          .sfdr-block(v-if="data.share.characteristics.benchmark_esg && ['fund', 'univers'].every(d => data.esg.carbon_footprint.map(d => d.key).includes(d))")
            .row
              .sfdr-text {{ t.carbon_footprint_difference || 'carbon_footprint_difference' }}
              .sfdr-value {{ format('.0%')(data.esg.carbon_footprint_difference) }}
          div.source(v-if="$root.lang.includes('be')") {{ t.source + ' ' + t[data.share.characteristics.management_company] }}
    .column(v-if="data.esg.carbon_intensity.data.length")
      brick(title="carbon_intensity" subtitle="subtitle_carbon_intensity")
      pdf-horizontalbar-icon(:data="data.esg.carbon_intensity.data" :options="{ format: '.2f', sort: d => ['fund', 'benchmark'].indexOf(d.key) }" subtitle="subtitle_carbon_intensity")
      .sfdr-block(v-if="data.share.characteristics.display_carbon_intensity_bench && data.share.characteristics.benchmark_esg && ['fund', 'univers'].every(d => data.esg.carbon_intensity.data.map(d => d.key).includes(d))")
        .row
          .sfdr-text {{ t.carbon_intensity_difference || 'carbon_intensity_difference' }}
          .sfdr-value {{ format('.0%')(data.esg.carbon_intensity_difference) }}
      div.source(v-if="$root.lang.includes('be')") {{ t.source + ' ' + t[data.share.characteristics.management_company] }}
    .column(v-if="data.esg.esg_impact_positif.data.length")
      brick(title="impact_positif" subtitle="subtitle_impact_positif")
      pdf-horizontalbar-icon.esg_impact_positif(:data="data.esg.esg_impact_positif.data" :options="{ formats: '.2%', sort: d => ['fund', 'benchmark'].indexOf(d.key) }" subtitle="impact_positif")
    .column(v-else)
      brick.empty
  .row(v-if="data.share.characteristics.esg_impact_table")
    .colum
      impact-esg-table(:characteristicsData="data.share.characteristics" :mapping="window.mapping")
      div.source(v-if="$root.lang.includes('be')") {{ t.source + ' ' + t[data.share.characteristics.management_company] }}
  .row
    brick.risk_disclaimer(title="definitions_esg" v-if="(data.share.characteristics.definition_esg_list || []).length > 0")
      div(v-html="t[definition] || definition" v-for="definition in data.share.characteristics.definition_esg_list.split(',')")
</template>

<script>
export const additions = {}
export default {
  props: { data: Object },
}
</script>
