import { memoize } from 'lodash'

const hexToRgb = (hex: string): [number, number, number] => {
  // Remove # if present
  hex = hex.replace('#', '')
  return [parseInt(hex.substring(0, 2), 16), parseInt(hex.substring(2, 4), 16), parseInt(hex.substring(4, 6), 16)]
}

const rgbToHex = (r: number, g: number, b: number): string => {
  // Ensure values are within 0-255 range
  r = Math.min(255, Math.max(0, Math.round(r)))
  g = Math.min(255, Math.max(0, Math.round(g)))
  b = Math.min(255, Math.max(0, Math.round(b)))

  return '#' + [r, g, b].map(x => x.toString(16).padStart(2, '0')).join('')
}

const generatePaletteLinear = memoize((fromColor: string, toColor: string, steps: number): string[] => {
  const fromRgb = hexToRgb(fromColor)
  const toRgb = hexToRgb(toColor)
  const palette: string[] = []

  for (let i = 0; i < steps; i++) {
    const factor = i / (steps - 1)
    // Linear interpolation for each RGB component
    const r = fromRgb[0] + (toRgb[0] - fromRgb[0]) * factor
    const g = fromRgb[1] + (toRgb[1] - fromRgb[1]) * factor
    const b = fromRgb[2] + (toRgb[2] - fromRgb[2]) * factor

    palette.push(rgbToHex(r, g, b))
  }

  return palette
})

export { generatePaletteLinear, hexToRgb, rgbToHex }
