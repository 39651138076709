<style>
.templates .cell-format {
  flex: unset !important;
}
</style>

<style scoped>
.ml-2 {
  margin-left: 8px;
}
.form {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 24px;
}
</style>

<template>
  <transition @leave="leave">
    <loader v-if="isLoading" />
  </transition>
  <div class="row">
    <h1 style="margin-right: 0">Templates</h1>
    <badge :count="templates.length" />
  </div>
  <subtitle style="font-style: italic; margin-top: -5px">{{ t['client-edition-templates-subtitle'] }}</subtitle>
  <div class="expand block">
    <spreadsheet
      class="stripped expand nosort"
      :data="templates"
      :options="{
        columns: [
          'format',
          'name',
          'Data Report',
          'Data Quality Report',
          'Last Update',
          'Usage',
          'Stylesheet',
          'actions',
        ],
        editable: false,
      }"
    >
      <template v-slot:cell-format="{ column, line }">
        <div class="tt" style="display: flex; justify-content: center" :tt="line.format?.toUpperCase()">
          <ui-asset class="file_icon" :name="'icon_' + (line.type?.toLowerCase() || 'pdf')" />
        </div>
      </template>
      <template v-slot:cell-data-quality-report="{ column, line }">
        <div>{{ line.dataQualityReportName }}</div>
      </template>
      <template v-slot:cell-name="{ column, line }">
        <div>{{ line.name }}</div>
      </template>
      <template v-slot:cell-last-update="{ column, line }">
        <div>{{ line.createdAt }}</div>
      </template>
      <template v-slot:cell-usage="{ column, line }">
        <div>{{ line.usage }}</div>
      </template>
      <template v-slot:cell-stylesheet="{ column, line }">
        <div>{{ line.stylesheetName }}</div>
      </template>
      <template v-slot:cell-data-report="{ column, line }">
        <div>{{ line.dataReportName }}</div>
      </template>
      <template v-slot:cell-actions="{ column, line }">
        <div class="row">
          <button class="ghost" tt="View" @click="edit(line)" v-if="line.readOnly">
            <svg-icon name="pt-icon-eye-open" />
          </button>
          <button class="ghost" tt="Edit" @click="edit(line)" v-else>
            <svg-icon name="pt-icon-edit" />
          </button>
          <button class="ghost" v-if="line.type === 'pdf'" :tt="t.duplicate" @click="openDuplicatePdf(line.id)">
            <svg-icon name="ic_file_copy" />
          </button>
          <button class="ghost" v-else tt="Download" @click="downloadExcel(line)">
            <svg-icon name="pt-icon-cloud-download" />
          </button>
          <button
            class="ghost"
            tt="Delete"
            @click="confirmRemoveId = line"
            v-if="line.usage === 0"
            :disabled="line.readOnly"
          >
            <svg-icon name="pt-icon-trash" />
          </button>
        </div>
      </template>
    </spreadsheet>
    <div class="row">
      <button class="primary" @click="openCreatePdf">{{ t.create_pdf_template }}</button>
      <button
        class="primary ml-2"
        v-if="excelTemplatesFeature === true"
        @click="$router.push({ path: $root.appath + 'excel-template' })"
      >
        {{ t.create_excel_template }}
      </button>
    </div>

    <popup :show="confirmRemoveId">
      <template v-slot:header>
        {{ t['confirmation_remove'] }}
      </template>
      <template v-slot:content>
        {{ t['text_remove'] }}
      </template>
      <template v-slot:action>
        <button class="secondary-action" @click="confirmRemoveId = null">{{ t['confirmation_btn_close'] }}</button>
        <button class="main-action" @click="remove(confirmRemoveId)">{{ t['confirmation_btn_delete'] }}</button>
      </template>
    </popup>

    <create-template-popup
      :show="showCreateTemplate"
      :templateToDuplicateId="templateToDuplicateId"
      @create-template="handleCreateTemplate"
      @close="handleCloseCreateTemplate"
    />
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import templateService from '@100-m/hauru/src/services/TemplateService'
import dataReportService from '@100-m/hauru/src/services/DataReportService'
import { commandrSDK } from '@100-m/hauru/src/features/commandr'
import { merge } from '@100-m/hauru/src/utils/merge'
import excelTemplateService from '@100-m/hauru/src/services/ExcelTemplateService'
import dataQualityReportService from '@100-m/hauru/src/services/DataQualityReportService'
import { downloadResponseAsFile } from '@100-m/hauru/src/services/RestService'
import Popup from '@100-m/hauru/src/components/ui/popup.vue'

const isLoading = ref(true)
const templates = ref([])
const confirmRemoveId = ref(null)
const excelTemplatesFeature = ref(config.excelTemplatesFeature)
const showCreateTemplate = ref(false)
const templateToDuplicateId = ref(null)

onMounted(async () => {
  templates.value = await getTemplates()
  getTemplateUsage()
  // NOTE: For performance reasons, we stop the loader and show the table as soon as we get the templates,
  //       and then we compute the template usage in the background, as it is slower (data from Hasura).
})

const getTemplates = async () => {
  isLoading.value = true
  const dqReportList = await dataQualityReportService.all()
  const partialTemplates = await templateService.findMany()
  const dataReportList = await dataReportService.list()
  const templatesInformation = partialTemplates.reduce((acc, partialTemplate) => {
    const dataReportName =
      dataReportList.find(dataReport => dataReport.id === partialTemplate.dataReportId)?.name || '-'
    const dataQualityReportName =
      dqReportList.find(dqr => dqr.id === partialTemplate.dataQualityCheckReportId)?.name || '-'
    acc.push({
      ...partialTemplate,
      dataReportName,
      dataQualityReportName,
      stylesheetName: partialTemplate.layout?.theme?.stylesheet || '-',
    })
    return acc
  }, [])
  isLoading.value = false
  return templatesInformation
}

const getTemplateUsage = async () => {
  const pressesTemplateNames = (await commandrSDK.get('/data/presses'))
    ?.v()
    .filter(press => !press.disabled)
    .map(press => press.template)
  templates.value = templates.value.map(template => {
    const nbPresses = pressesTemplateNames?.filter(pressTemplateName => pressTemplateName === template.name).length
    return {
      ...template,
      usage: nbPresses,
    }
  })
}

const openCreatePdf = async () => {
  showCreateTemplate.value = true
}

const openDuplicatePdf = async templateId => {
  templateToDuplicateId.value = templateId
  showCreateTemplate.value = true
}

const handleCreateTemplate = async templateData => {
  if (!templateData.name || !templateData.dataReportId) {
    return $root.toast({ description: $root.t.missing_mandatory_fields, type: 'error', timeout: 5000 })
  }

  if (templates.value.find(template => template.name === templateData.name)) {
    return $root.toast({ description: $root.t.template_name_already_exists, type: 'error', timeout: 5000 })
  }

  const defaultTemplate = {
    theme: {
      prefix: 'grk',
      page_size: 'A4',
      page_orientation: 'portrait',
      spacing: 3,
      pageMargin: 2,
    },
    nodes: [
      {
        type: 'column',
        nodes: [
          { component: 'header' },
          { options: { title: 'block' }, component: 'block' },
          { options: { title: 'block' }, component: 'block' },
          { component: 'footer' },
        ],
      },
    ],
  }
  const layout = templateData.layout || merge(defaultTemplate, config.defaultTemplate)

  await templateService.create({
    templateInput: {
      name: templateData.name,
      dataReportId: templateData.dataReportId,
      layout,
      type: 'pdf',
    },
  })

  edit({ name: templateData.name })
}

const handleCloseCreateTemplate = () => {
  templateToDuplicateId.value = null
  showCreateTemplate.value = false
}

const edit = templateInfo => {
  if (templateInfo.type === 'xlsx') {
    $root.$router.push({ path: $root.appath + 'excel-template', query: { name: templateInfo.name } })
  } else {
    $root.$router.push(`/builder/?templateName=${templateInfo.name}`)
  }
}

const remove = async ({ name, id, readOnly, type }) => {
  if (readOnly) return
  if (type === 'xlsx') {
    // For Excel templates
    await excelTemplateService.delete(id)
  } else {
    // For PDF templates
    await templateService.deleteManyByName({ name, limit: 1 })
  }
  confirmRemoveId.value = null
  templates.value = await getTemplatesWithUsage()
}

const downloadExcel = async templateInfo => {
  // console.log('downloadExcel', templateInfo)
  const response = await excelTemplateService.download(templateInfo.id)
  downloadResponseAsFile(response, 'application/vnd.ms-excel', `${templateInfo.name}-${templateInfo.id}.xlsx`)
}
</script>

<script>
export default {
  icon: 'ic_picture_as_pdf',
}
</script>
