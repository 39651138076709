<template>
  <builder-chart v-bind="{ ...props, data, nxChartOptions, chartOptions }">
    <template v-for="(_, name) in $slots" #[name]="slotData">
      <slot :name="name" v-bind="slotData"></slot>
    </template>
  </builder-chart>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { bar as api } from '../composables/builderOptions'
import { BuilderProps } from '../composables/builderComponent'
import { bar } from './stories'
import useTranslations from '../composables/translations'

const viz = 'bar'
const props = defineProps<BuilderProps>()
const { translateData } = useTranslations(props)

const nxChartOptions = computed(() => {
  return {
    viz,
    lib: props.options.horizontal ? 'nx' : 'dx',
    legendOptions: { position: 'bottom' },
  }
})
function parseLegendPosition(position?: string) {
  if (!position || position === 'default') return 'bottom'
  if (position === 'floating') return 'absolute'
  return position
}

const chartOptions = computed(() => {
  return {
    legendOptions: {
      position: parseLegendPosition(props.options.legendPosition),
      shape: props.options?.legendShape || 'square',
      orientation: props.options?.legendOrientation || 'horizontal',
    },
  }
})
</script>

<script lang="ts">
export default {
  api,
  viz: 'bar',
  styles: {
    'bar-value': `font-weight: bold;`,
    'bar-label': '',
    'bar-bar': {
      name: 'Bar',
      css: ``,
    },
    'bar-group': {
      name: 'Bar group',
      css: ``,
    },
  },
  story: bar,
}
</script>
