<style scoped>
.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.subtitle {
  font-style: italic;
  margin-top: -5px;
}
.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
}
.content {
  display: flex;
  flex-direction: row;
}
:deep(.modal-container) {
  width: 550px !important;
}
.form {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 12px;
  margin-bottom: 24px;
}
.form-variables {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}
.form-variables :deep(input) {
  width: 100%;
}
.filename {
  flex: 1;
}
.form-fallback {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.image-preview-panel {
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 1px solid var(--colors-gray1);
  margin-top: 24px;
  height: fit-content;
  padding: 6px 6px;
}
.image-preview-panel img {
  display: flex;
  flex-direction: row;
  flex: 1;
  height: auto;
  width: 250px;
}
.image-preview-panel .close-button {
  position: absolute;
  right: 20px;
  top: 48px;
}
.image-preview-panel .close-button > button {
  background: rgba(233, 233, 233, 0.6);
  padding: 6px;
  width: 32px;
  height: 32px;
}
.fallback-tag {
  width: auto !important;
  height: 20px !important;
  font-size: 9px !important;
  padding: 1px 6px !important;
  margin-left: 8px !important;
  line-height: 18px !important;
  border-radius: 3px;
  border: 1px solid black;
}
</style>

<template>
  <transition @leave="leave">
    <loader v-if="loading" />
  </transition>

  <div class="header">
    <div class="column" style="gap: 8px">
      <div class="row">
        <h1 style="margin-right: 0">{{ `${$root.t.image_folder_page.title}: ${imageFolder?.name}` }}</h1>
      </div>
      <p class="subtitle">
        {{ $root.t.image_folder_page.subtitle }}
      </p>
    </div>
  </div>

  <div class="expand template-container block" v-if="loading === false">
    <div class="container">
      <div class="column">
        <span v-if="!formattedImages.length" style="margin: 16px">{{ $root.t.image_folder_page.no_images }}</span>

        <div v-else class="content">
          <spreadsheet
            v-if="formattedImages?.length"
            class="stripped expand spreadsheet"
            :data="formattedImages"
            :options="{
              columns: dynamicColumns,
              editable: false,
            }"
          >
            <template v-slot:cell-fund-id="{ line }">
              <span v-if="line.isFallback" class="fallback-tag">
                {{ $root.t.image_folder_page.fallback_tag_label }}
              </span>
            </template>
            <template v-slot:cell-actions="{ line }">
              <div class="row" style="align-items: end">
                <button class="ghost" :tt="$root.t.image_folder_page.edit" @click="editImage(line.id)">
                  <ui-asset name="icon_edit" color="var(--colors-text)" />
                </button>
                <button class="ghost" :tt="$root.t.image_folder_page.delete" @click="setConfirmDeleteImageId(line.id)">
                  <ui-asset name="icon_trash" color="var(--colors-text)" />
                </button>
                <button class="ghost" :tt="$root.t.image_folder_page.preview" @click="previewImage(line.id)">
                  <ui-asset name="icon_eye" color="var(--colors-text)" />
                </button>
              </div>
            </template>
          </spreadsheet>

          <div class="image-preview-panel" v-if="previewedImage">
            <div class="display: flex; flex: 1">
              <img :src="previewedImage.publicUrl" alt="Image Preview" />
              <div class="close-button">
                <button @click="closeImagePreview">
                  <ui-asset name="icon_cross" :width="18" :height="18" color="var(--colors-text)" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" style="gap: 8px">
      <button class="secondary" @click="cancel">
        {{ $root.t.image_folder_page.cancel }}
      </button>
      <button class="primary" @click="createImage">
        {{ $root.t.image_folder_page.add }}
      </button>
    </div>
  </div>

  <popup :show="showCreateImagePopup">
    <template v-slot:header>
      {{ form.imageId ? $root.t.image_folder_page.update_image : $root.t.image_folder_page.create_image }}
    </template>
    <template v-slot:content>
      <div class="form">
        <div class="row" style="gap: 16px; align-items: center">
          <button class="primary" @click="triggerFileInput">
            {{ $root.t.image_folder_page.import }}
            <input class="hidden" type="file" ref="fileInput" accept="image/*" @change="importFile" />
          </button>
          <span class="filename">{{ form.file?.name || $root.t.image_folder_page.no_uploaded_file }}</span>
        </div>
        <div class="form-fallback">
          <input type="checkbox" v-model="form.isFallback" />
          {{ $root.t.image_folder_page.is_fallback }}
        </div>
        <div v-if="!form.isFallback" class="form-variables">
          <image-variable-inputs
            v-for="param in variablesInputs"
            :key="param.name"
            :variableDefinition="param"
            :missingDependencies="missingDependencies?.[param.name]"
            :inputData="inputData?.[param.name]"
            :value="form.variables?.[param.name]"
            @update:value="
              value => {
                form.variables[param.name] = value
                updateVariable(param, value)
              }
            "
          />
        </div>
      </div>
    </template>
    <template v-slot:action>
      <button class="secondary-action" @click="showCreateImagePopup = false">
        {{ $root.t.image_folder_page.cancel }}
      </button>
      <button class="main-action" @click="saveImage" :disabled="!form.file?.path && !form.file?.buffer">
        {{ form.imageId ? $root.t.image_folder_page.update : $root.t.image_folder_page.create }}
      </button>
    </template>
  </popup>

  <popup :show="confirmDeleteImageId">
    <template v-slot:header>
      {{ $root.t.confirmation_remove }}
    </template>
    <template v-slot:content>
      {{ $root.t.image_folder_page.delete_image_confirmation }}
    </template>
    <template v-slot:action>
      <div class="row" style="margin-top: 16px">
        <button class="secondary-action" @click="confirmDeleteImageId = null">
          {{ $root.t.image_folder_page.cancel }}
        </button>
        <button class="main-action" @click="deleteImage(confirmDeleteImageId || NaN)">
          {{ $root.t.image_folder_page.delete }}
        </button>
      </div>
    </template>
  </popup>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from 'vue'

import imageService from '@100-m/hauru/src/services/ImageService'
import imageFolderService, { ImageFolder } from '@100-m/hauru/src/services/ImageFolderService'
import specialtyService, { Specialty } from '@100-m/hauru/src/services/SpecialtyService'
import { useDrVariables, VariableDefinition } from '@100-m/hauru/src/components/form/drVariables/lib'
import {
  deleteFile,
  getAssetPublicUrl,
  uploadAssetImage,
} from '@100-m/hauru/src/applications/builder/lib/assetManagement'

const imageFolder = ref<ImageFolder | null>(null)
const loading = ref<boolean>(false)
const showCreateImagePopup = ref<boolean>(false)
const confirmDeleteImageId = ref<number | null>(null)
const specialty = ref<Specialty | null>(null)
const fileInput = ref<HTMLButtonElement | null>(null)
const variablesInputs = ref<VariableDefinition[]>([])
const previewedImage = ref<{ id: number; publicUrl: string } | null>(null)

const form = ref<{
  imageId?: number
  file?: { name?: string; path?: string; buffer?: ArrayBuffer }
  variables: Record<string, string> | null
  isFallback: boolean
}>({
  imageId: undefined,
  file: undefined,
  variables: {},
  isFallback: false,
})

const { partials, runParameters, inputData, missingDependencies, initDrVariables, updateVariable } = useDrVariables()

onMounted(async () => {
  loading.value = true

  const imageFolderId = typeof $root.$route.query.id === 'string' && parseInt($root.$route.query.id)

  if (imageFolderId) {
    imageFolder.value = await imageFolderService.getById({ id: imageFolderId })

    specialty.value = await specialtyService.getByName(imageFolder.value?.specialtyName)

    await initDrVariables(
      specialty.value?.settings.filter(v => imageFolder.value?.variables.includes(v.name)),
      {},
    )

    variablesInputs.value = [...partials.value, ...runParameters.value]
  }

  loading.value = false
})

const formattedImages = computed(() => {
  return (
    imageFolder.value?.images.map(image => ({
      id: image.id,
      isFallback: !Object.keys(image.variables || {}).length,
      ...image.variables,
    })) || []
  )
})

const dynamicColumns = computed(() => {
  if (!imageFolder.value?.images) return []

  const variables = specialty.value?.settings.flatMap((v: { name: string }) =>
    imageFolder.value?.variables.includes(v.name) ? [v.name] : [],
  )

  return [...(variables || []), 'actions']
})

async function deleteImage(imageId: number) {
  const deletedImage = await imageService.deleteOne({ id: imageId })

  if (deletedImage.id) {
    await deleteFile({
      filenameWithPath: deletedImage.path,
      isPrivateFile: false,
    })

    imageFolder.value.images = imageFolder.value?.images.filter(image => image.id !== imageId)

    $root.toast({
      description: $root.t.image_folder_page.delete_image_success,
      type: 'success',
      timeout: 5000,
    })

    confirmDeleteImageId.value = null

    closeImagePreview()
  }
}

function triggerFileInput() {
  fileInput.value?.click()
}

async function importFile(event: Event) {
  const file = (event.target as HTMLInputElement).files?.[0]

  if (!file) {
    return $root.toast({ description: $root.t.image_folder_page.input_error, type: 'error', timeout: 5000 })
  }

  const validExtensions = ['jpg', 'jpeg', 'png']
  const fileExtension = file.name.split('.').pop() || ''

  if (!validExtensions.includes(fileExtension.toLowerCase())) {
    return $root.toast({ description: $root.t.image_folder_page.invalid_file_type, type: 'error', timeout: 5000 })
  }

  const reader = new FileReader()
  reader.onload = async event => {
    const arrayBuffer = event.target?.result

    if (!arrayBuffer) {
      return
    }

    form.value.file = { name: file.name, buffer: arrayBuffer as ArrayBuffer }
  }

  reader.readAsArrayBuffer(file)
}

function setConfirmDeleteImageId(imageId: number | null) {
  confirmDeleteImageId.value = imageId
}

function previewImage(id: number) {
  const image = imageFolder.value?.images.find(img => img.id === id)

  if (!image) {
    return
  }

  const publicUrl = getAssetPublicUrl({ filenameWithPath: image.path })

  previewedImage.value = { id, publicUrl }
}

function closeImagePreview() {
  previewedImage.value = null
}

function createImage() {
  form.value = {
    imageId: undefined,
    file: undefined,
    variables: {},
    isFallback: false,
  }

  showCreateImagePopup.value = true
}

function editImage(imageId: number) {
  const image = imageFolder.value?.images.find(i => i.id === imageId)
  const isFallback = !Object.keys(image?.variables || {}).length

  form.value = {
    imageId,
    variables: image?.variables || {},
    file: {
      name: image?.path.split('/').pop(),
      path: image?.path,
    },
    isFallback,
  }

  showCreateImagePopup.value = true
}

async function saveImage() {
  let path

  const isFallback = !Object.keys(form.value.variables || {}).length

  const fileExtension = form.value.file?.name?.split('.').pop()

  const filename = `${imageFolder.value?.name}-${
    isFallback
      ? 'fallback'
      : Object.values(form.value.variables || {})
          .filter(v => !!v)
          .join('-')
  }.${fileExtension}`

  if (form.value.file?.buffer || filename !== form.value.file?.name) {
    const { bucketPath } = await uploadAssetImage({
      arrayBuffer: form.value.file?.buffer as ArrayBuffer,
      name: filename,
      subFolder: imageFolder.value?.name || '',
      isPrivateFile: false,
    })

    if (!bucketPath) {
      return $root.toast({ description: $root.t.image_folder_page.upload_error, type: 'error', timeout: 5000 })
    }

    path = bucketPath
  }

  if (!form.value.isFallback && !Object.keys(form.value.variables || {}).length) {
    return $root.toast({ description: $root.t.image_folder_page.no_variables_selected, type: 'error', timeout: 5000 })
  }

  const imageInput = {
    path,
    imageFolderId: imageFolder.value?.id,
    variables:
      !form.value.isFallback && Object.keys(form.value.variables || {}).length ? form.value.variables : undefined,
  }

  try {
    if (form.value.imageId) {
      await imageService.update({ id: form.value.imageId, imageInput })

      imageFolder.value.images = imageFolder.value.images.map(image =>
        image.id === form.value.imageId ? { ...image, path: imageInput.path, variables: imageInput.variables } : image,
      )
    } else {
      const res = await imageService.create({ imageInput })

      imageFolder.value.images = [
        ...(imageFolder.value?.images || []),
        {
          id: res.id,
          path: imageInput.path ?? '',
          variables: imageInput.variables ?? {},
        },
      ]
    }
  } catch (error) {
    return $root.toast({ description: error?.message, type: 'error', timeout: 5000 })
  }

  showCreateImagePopup.value = false

  $root.toast({
    description: form.value.imageId
      ? $root.t.image_folder_page.update_image_success
      : $root.t.image_folder_page.create_image_success,
    type: 'success',
    timeout: 5000,
  })

  $root.$router.push({
    path: $root.appath + 'image-folder',
    query: { id: imageFolder.value?.id?.toString() },
  })
}

// ⚠️ Doesn't go back to prevent issues with hauru router overrides
function cancel(): void {
  $root.$router.push({
    path: $root.appath + 'image-folders',
  })
}
</script>
