<style scoped>
h1 .search-filters {
  display: flex;
  flex: 1;
  margin-left: 10px;
}
h1 .search-filters .autocomplete {
  min-width: 90% !important;
}
:deep() .cell-download {
  max-width: 30px;
  left: 0px !important;
}
:deep() .cell-download .resizer {
  display: none;
}
:deep() .header .cell > * {
  justify-content: start;
  font-weight: bold;
  font-size: 14px;
}
:deep() .cell-download > * {
  padding: 0 !important;
}
:deep() .cell-download img {
  min-width: auto;
  width: 30px;
}
:deep() .cell-check {
  left: 0px !important;
  max-width: 30px;
}
:deep() .spreadsheet .group [type='checkbox'] {
  width: 30px;
  margin-left: -6px;
}
[type='search'] {
  font: var(--p1);
}

:deep() .toolbar-break {
  display: none;
}
.app-client-edition .screen-documents .block {
  padding: 16px;
}
:deep() .line.header {
  left: 24px !important;
}
.file_icon {
  pointer-events: all;
  cursor: pointer;
}
.download-selected {
  background: var(--primary);
}
</style>

<template>
  <transition name="leave">
    <loader v-if="isLoading" />
  </transition>
  <h1>
    <span>{{ t[$root.screen.path] }}</span>
    <div class="search-filters">
      <autocomplete
        class="right"
        :data="autocomplete_data"
        :options="{ placeholder: t.search }"
        v-model="activated_filters"
        @update:modelValue="autocomplete_input"
        @keyup.native="search = $event.target.value"
        @focusout.native="search = ''"
      />
    </div>
  </h1>
  <subtitle style="font-style: italic; margin-top: -5px">{{ t['client-edition-documents-subtitle'] }}</subtitle>
  <block class="expand">
    <div class="row center left">
      <button class="download-selected" @click="downloadSelected(selected)" v-if="selected.length">
        {{ t.download_documents }} ({{ selected.length }})
      </button>
    </div>
    <spreadsheet
      v-if="filtered_files.length"
      :data="filtered_files"
      :options="{
        regroup: ['empty'],
        level: 10,
        filter: searchFn,
        sort: ['p1'],
        group: Array(fs.map(p => p.split('/').length).max())
          .fill()
          .map((_, i) => 'p' + i),
        columns: ['check', 'download', 'filename'].concat(document_info).flat().unique(),
        editable: false,
        collapseAll: true,
      }"
    >
      <template v-slot:header-check>
        <input
          type="checkbox"
          :checked="selected.length === fs.length"
          :indeterminate.prop="selected.length > 0 && selected.length !== fs.length"
          @click.stop="selected = selected.length === fs.length ? [] : files"
        />
      </template>
      <template v-slot:cell-check="s">
        <input
          type="checkbox"
          :checked="checkSelection(s.line, selected)"
          @click.stop="selected = selected.map().toggle(s.line)"
          @mousedown.prevent.stop=""
        />
      </template>
      <template v-slot:header-download>
        <button
          style="height: 1.5rem; width: 1.5rem; color: var(--primary); background: none"
          tt="Download All"
          @click="downloadAll"
        >
          <div @mousedown.prevent.stop>
            <ui-asset :width="24" :height="24" name="icon_download"></ui-asset>
          </div>
        </button>
      </template>
      <template v-slot:cell-download="s">
        <ui-asset
          :name="'icon_' + s.line.filename?.split('.').last().lower()"
          @click="downloadSelected([s.line])"
          class="file_icon"
        />
      </template>
      <template v-slot:group="{ line }">
        <input
          type="checkbox"
          :checked="line._group.every(v => checkSelection(v, selected))"
          @mousedown.prevent.stop=""
          :indeterminate.prop="
            line._group.some(v => selected.includes(v)) > 0 && !line._group.every(v => selected.includes(v))
          "
          @click.stop="
            selected = line._group.every(v => selected.includes(v))
              ? selected.filter(v => !line._group.includes(v))
              : selected.concat(line._group.filter(v => !selected.includes(v)))
          "
        />
        <div class="key">{{ line._key }}</div>
        <div class="length">{{ line._length }}</div>
      </template>
    </spreadsheet>
  </block>
</template>

<script>
import JSZip from 'jszip'
import { useImpressions } from '../composables/useImpressions'
import { useProgress } from '../composables/useProgress'
import { computed } from 'vue'
import { useRuns } from '../composables/useRuns'
import { getAssetPublicUrl } from '@100-m/hauru/src/applications/builder/lib/assetManagement'

export const additions = { icon: 'ic_folder' }

export default {
  setup() {
    const { impressions, loaded, initialized } = useImpressions()
    const { runs, loaded: rLoaded } = useRuns()
    const { progress } = useProgress([loaded, rLoaded])
    const isLoading = computed(() => progress.value !== 1)

    return { impressions, isLoading, runs }
  },
  data() {
    return {
      selected: [],
      search: '',
      searchFn: x => !this.search || x.alias.includes(this.search),
    }
  },
  computed: {
    files() {
      return this.impressions
        .group('alias')
        .map(v => v.last())
        .v()
        .map(v =>
          v.alias.split('/').reduce((acc, p, i, ps) => ((acc[i === ps.length - 1 ? 'filename' : 'p' + i] = p), acc), v),
        )
        .map(el => {
          const isin = el.filename.split('-')[1]
          const responsable = this.runs[el.run_id]?.context?.responsable || 'N/A'
          const language = this.runs[el.run_id]?.context?.language || 'N/A'
          const period = this.runs[el.run_id]?.context?.period || 'N/A'
          return { ...el, fund_name: el.filename.split('-')[0], isin, responsable, language, period }
        })
    },
    activated_filters() {
      let query_params = this.$route.query
      let active_filters = query_params.map(filter_value => filter_value.split('|'))
      const filters = []
      Object.entries(active_filters).forEach(([filter_name, filter_values]) => {
        if (filter_name === 'year') return
        filter_values.forEach(value => {
          filters.push(this.format_filter_data(filter_name, value))
        })
      })
      return filters
    },
    fs() {
      return (this.impressions || {}).v().map('alias').unique()
    },
    autocomplete_data() {
      const filter_criterias = this.document_info
      let data = this.files.reduce((grouped_filters, file) => {
        filter_criterias.forEach(criteria => {
          if (typeof file[criteria] === 'string') {
            let filter_value = file[criteria].split('.')[0]
            grouped_filters[criteria] = grouped_filters[criteria] || {}
            grouped_filters[criteria][filter_value] = filter_value
          }
        })
        return grouped_filters
      }, {})
      return data
    },
    filtered_files() {
      let query_params = this.$route.query
      if (query_params.keys().length) {
        const filters = Object.entries(query_params)
          .filter(([k, v]) => !['search', 'selected', 'scheduled', 'year'].includes(k))
          .map(([k, v]) => [k, v.split('|')])
        return this.files.filter(d =>
          filters.every(([k, vs]) =>
            vs.some(
              v =>
                (d[k] && d[k].replace('.', '') == v) ||
                (d[k] && d[k].includes(v)) ||
                (/^>/.test(v) && d[k] > v.slice(1)) ||
                (/^</.test(v) && d[k] < v.slice(1)),
            ),
          ),
        )
      } else {
        return this.files
      }
    },
  },
  methods: {
    checkSelection(line, selection) {
      var i
      for (i = 0; i < selection.length; i++) {
        if (JSON.stringify(selection[i]) === JSON.stringify(line)) {
          return true
        }
      }
      return false
    },
    async downloadSelected(selected) {
      const urls = selected.map(el => getAssetPublicUrl({ filenameWithPath: el.alias }))
      this.downloadFiles(urls)
    },
    async downloadAll() {
      const urls = this.fs.map(el => getAssetPublicUrl({ filenameWithPath: el }))
      this.downloadFiles(urls)
    },
    async downloadFiles(urls) {
      if (!urls.length) {
        return
      }

      if (urls.length === 1) {
        const fileData = await fetch(urls[0])
        const blob = await fileData.blob()

        const filename = urls[0].split('/').last()

        return this.downloadBlob(blob, filename)
      }

      const zip = new JSZip()
      const uniqueUrls = [...new Set(urls)]

      await Promise.all(
        uniqueUrls.map(async url => {
          const zippedFilename = uniqueUrls.length !== urls.length ? url.replace(/\//g, '_') : url.split('/').pop()
          const fileData = await fetch(url)
          const fileBlob = await fileData.blob()

          zip.file(zippedFilename, fileBlob)
        }),
      )

      const blob = await zip.generateAsync({ type: 'blob' })
      const filename = `docs-${new Date().toISOString()}.zip`

      this.downloadBlob(blob, filename)
    },
    downloadBlob(blob, filename) {
      const link = document.createElement('a')
      link.setAttribute('href', URL.createObjectURL(blob))
      link.setAttribute('style', 'display: none;')
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    autocomplete_input(event) {
      let event_array = event.map(v => v.split('.'))
      let grouped_events = event_array.group('0')
      let selected_filters = grouped_events.map(g => g.map('1').join('|'))
      let new_filter_selected = this.$route.query.filter((v, k) => !this.$route.query.keys().includes(k))
      let query = { ...selected_filters, ...new_filter_selected }
      this.$router.push({ query })
    },
    format_filter_data(name, value) {
      return name + '.' + value
    },
  },
}
</script>
