<template>
  <builder-block v-bind="props">
    <div class="footnote-items start flex flex-col justify-between space-y-1">
      <span v-for="v in globalFootnotes" class="footnote-item" :key="v.index">
        <sup>{{ v.index }}</sup>
        <span class="footnote-text ml-1" v-html="v.value"></span>
      </span>
    </div>
  </builder-block>
</template>

<script setup lang="ts">
import useTranslations from '../composables/translations'

const props = defineProps(['data', 'options', 'context'])
const { globalFootnotes } = useTranslations(props, 'global')
</script>
<script lang="ts">
export default {
  styles: { 'footnote-text': '' },
  story: {
    props: {
      options: {
        title: 'Notes',
      },
      context: {
        translations: {},
        globalFootnotes: [
          { key: 'Test', value: 'Footnote 1' },
          { key: 'Test 2', value: 'Footnote 2' },
        ],
      },
    },
  },
}
</script>
