<template>
  <popup :show="show">
    <template #header>
      {{
        props.dataReportToDuplicateId
          ? $root.t.duplicate_data_report_popup.duplicate_title
          : $root.t.duplicate_data_report_popup.duplicate_title
      }}
    </template>
    <template #content>
      <loading-icon v-if="loading" :size="36" style="justify-content: center; padding: 12px 0" />
      <div v-else class="form">
        <label>{{ $root.t.duplicate_data_report_popup.data_report_name_label }}</label>
        <input
          type="text"
          style="width: 100%"
          v-model="duplicateDataReportForm.name"
          :placeholder="$root.t.duplicate_data_report_popup.data_report_name_placeholder"
        />
      </div>
    </template>
    <template #action>
      <button class="secondary-action" @click="closePopup">{{ $root.t.cancel }}</button>
      <button class="main-action" @click="duplicateDataReport" :disabled="loading || !duplicateDataReportForm.name">
        {{ $root.t.duplicate_data_report_popup.create }}
      </button>
    </template>
  </popup>
</template>

<script setup lang="ts">
import { ref, defineEmits, onMounted, watch } from 'vue'
import popup from '@100-m/hauru/src/components/ui/popup.vue'
import dataReportService from '@100-m/hauru/src/services/DataReportService'
import { DataReportDefinition } from '../../../builder/builder'

const props = defineProps<{
  show: boolean
  dataReportToDuplicateId: number
}>()

const emit = defineEmits(['duplicate-data-report', 'close'])

const loading = ref<boolean>(false)
const dataReportToDuplicate = ref<DataReportDefinition | null>(null)
const duplicateDataReportForm = ref<{ name: string | null }>({
  name: null,
})

const fetchData = async () => {
  if (!props.dataReportToDuplicateId) {
    return
  }

  loading.value = true

  dataReportToDuplicate.value = await dataReportService.getById(props.dataReportToDuplicateId)

  duplicateDataReportForm.value = {
    name: 'Copy - ' + dataReportToDuplicate.value?.name,
  }

  loading.value = false
}

onMounted(fetchData)

watch(
  () => props.dataReportToDuplicateId,
  async (newVal, oldVal) => {
    if (newVal !== oldVal) {
      await fetchData()
    }
  },
)

const closePopup = () => {
  dataReportToDuplicate.value = null

  emit('close')
}

const duplicateDataReport = () => {
  emit('duplicate-data-report', {
    ...dataReportToDuplicate.value,
    name: duplicateDataReportForm.value.name,
  })

  dataReportToDuplicate.value = null
}
</script>

<style scoped>
.form {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 24px;
}
</style>
