import { RestService } from './RestService'
import * as cmdr from '../features/commandr'

export class StatusService extends RestService {
  constructor() {
    super()
    this.baseRoute = 'status/'
  }

  async getDbStructure() {
    return await this.get(`${this.baseRoute}db-structure`)
  }

  async getDbMigrations() {
    return await this.get(`${this.baseRoute}db-migrations`)
  }

  async getAllServices() {
    return await this.get(`${this.baseRoute}all-services`)
  }

  async getHasuraData() {
    return [
      {
        key: 'config.templateTpl',
        value: await cmdr.get('/config/templateTpl'),
        description: 'The Builder url',
        default: `${document.location.href.split($root.router.app)[0]}builder/`,
        // for example: https://kc.nx.digital/impress/#/builder/
      },
      {
        key: 'data.commands',
        value: await cmdr.get('/data/commands '),
        description: 'The commands available in the workflow engine',
        default: ['generate'],
      },
      {
        key: 'data.run_id',
        value: await cmdr.get('/data/run_id '),
        description: 'The latest run ID used in the app',
        default: 'todo',
      },
      {
        key: 'data.runs_years',
        value: await cmdr.get('/data/runs_years'),
        description: 'The default year to filter the runs',
        default: { 2024: true }, // TODO 3 last years found in DB
      },
    ]
  }

  setHasuraKey(key, value) {
    return cmdr.update(key, value)
  }
}

const service = new StatusService()
export default service
