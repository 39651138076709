export const generateStudioUrl = ({ query = 'query {}', variables = '{}', token, endpoint }) => {
  variables = typeof variables === 'string' ? variables : JSON.stringify(variables, null, 2)
  endpoint = endpoint || config?.graphqlEndpoint
  token = token || $root?.profile?.idToken
  const q =
    query.length > 5000
      ? query
          .replace(/\s+/g, ' ')
          .replace(/\s?{\s?/g, '{')
          .replace(/\s?}\s?/g, '}')
          .replace(/\s?:\s?/g, ':')
      : query
  const resolvedUrl = `https://studio.apollographql.com/sandbox?endpoint=${encodeURI(
    new URL(endpoint, location.origin).href,
  )}&document=${encodeURI(q)}&variables=${encodeURI(variables)}&headers=${encodeURI(
    JSON.stringify({
      Authorization: `Bearer ${token}`,
      'x-client': `${import.meta.env.VITE_PROJECT} (from Apollo Studio)`,
      'x-caller-url': window.location.href,
    }),
  )}`

  return resolvedUrl
}
