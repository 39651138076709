<style scoped>
.tag input[type='checkbox'] {
  width: 16px;
  height: 16px;
  margin-top: 1px;
  margin-right: 8px;
}

.icon {
  margin-right: 8px;
  fill: var(--primary);
}

.ac.primary .icon {
  fill: white;
}

.nowrap {
  white-space: nowrap !important;
  flex-wrap: nowrap !important;
}

h3 {
  margin-bottom: 8px;
}

.card {
  padding: 16px;
  border: 4px solid transparent;
  border-radius: 4px;
  background: white;
  box-shadow: 0 2px 4px 0 hsla(198, 45%, 10%, 0.12);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  align-items: flex-start;
  justify-content: space-between;
}

.tag {
  padding: 2px 4px;
  border-radius: var(--border-radius);
}

h3 .tag {
  margin: auto 8px auto 0;
}

h3 .count {
  margin: auto 0 auto 8px;
}

.link {
  color: var(--primary);
}

.details {
  width: 100%;
  text-decoration: underline;
}

.card.selected-run {
  background: var(--primary);
}

.card.selected-run h3,
.card.selected-run .row.report,
.card.selected-run .link,
.card.selected-run .ac.primary .icon {
  color: white;
  fill: white;
}

.card .row {
  margin: 8px 0;
}

button.ac .column:not(:first-of-type) {
  margin-left: 4px;
  padding-top: 1px;
}

.lang.icon {
  margin: -4px 8px 0 0;
  min-width: 0;
}

.admin-alert {
  color: var(--negative);
  background-color: #ffe5e5;
  font-size: 12px;
  padding: 4px 8px;
  border-radius: 4px;
}

.preview {
  width: 100%;
  height: 100%;
  background-color: rgb(227, 227, 227);
}
</style>

<template>
  <transition @leave="leave">
    <loader v-if="isLoading" />
  </transition>
  <div class="row">
    <h1 style="margin-right: 0">{{ t['my-actions'] }}</h1>
    <notification-badge :count="myRuns.length" />
  </div>
  <subtitle style="font-style: italic; margin-top: -5px">{{ t['client-edition-my-actions-subtitle'] }}</subtitle>
  <div class="row expand" style="margin-bottom: 4px">
    <div class="column center" v-if="isAdmin()">
      <div class="row nowrap">
        <div class="column" style="margin-right: 12px">{{ t.admin_view }}</div>
        <div class="column">
          <toggle-switch
            @toggle="onToggleAdmin"
            :is-checked="true"
            unchecked-color="var(--secondary)"
            checked-color="var(--primary)"
          />
        </div>
      </div>
    </div>
    <div class="column center">
      <div class="nowrap" style="padding-right: 8px">{{ t.period_domain }} :</div>
    </div>
    <div class="column center">
      <select v-model="currentPeriodDomain">
        <option :value="null">{{ t.all }} ({{ myRuns.length }})</option>
        <option v-for="x in periodDomainValues" :key="x" :value="x">
          {{ x }} ({{ countMyRunsByPeriodDomain(x) }})
        </option>
      </select>
    </div>
    <div class="column center">
      <div class="row nowrap">
        <div v-for="v in Object.keys(checkedTagTypes)" :key="v" class="column">
          <div class="tag" :style="tagStyle(tagTypeCode(v))" style="text-align: left; margin-right: 12px">
            <input
              type="checkbox"
              :checked="checkedTagTypes[v]"
              @click.stop="checkedTagTypes[v] = !checkedTagTypes[v]"
            />
            <span>{{ t[v] }} ({{ countMyRunsByTagType(v) }})</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row top" v-if="myRuns.length === 0" style="padding: 8px; flex-grow: 1000">
    <div class="column">{{ t.no_actions }}</div>
  </div>
  <template v-else>
    <div class="row" v-if="myFilteredRuns.length > 0">
      <div class="column nowrap" style="height: calc(100vh - 130px); overflow: auto">
        <div v-for="r in myFilteredRuns" :key="r.id" :class="['card', { 'selected-run': r.id === selectedRunId }]">
          <h3 class="nowrap">
            <div class="tag" :style="tagStyle(tagTypeCode(lastStep(r).type))">{{ t[lastStep(r).type] }}</div>
            <strong>{{ lastStep(r).name }}</strong>
            <span class="count">({{ lastStep(r).id }}/{{ r.workflow.actions.v().length }})</span>
            <div
              class="task"
              :class="[lastLog(r).status, { skipped: lastLog(r).skipped && lastLog(r).skipped !== 'rerun' }]"
            ></div>
          </h3>
          <div class="row report" v-if="r.context.fund_name">
            <div>
              <svg-icon class="lang icon" :name="'flag-' + r.context.language.lower().slice(0, 2)" />
            </div>
            <div class="nowrap">
              <span>{{ r.context.fund_name }}</span>
            </div>
          </div>
          <div class="row report nowrap">
            <div v-if="!r.context.fund_name">
              <svg-icon class="lang icon" :name="'flag-' + r.context.language.lower().slice(0, 2)" />
            </div>
            <div class="nowrap">
              <span>{{ periodDomain(r) }}</span>
              <span v-if="r.context.template">- {{ r.context.template.titleize() }}</span>
            </div>
          </div>
          <div class="row">
            <div class="column">
              <button class="ac primary" @click.stop="validate_step(r.id, r.logs.v().last().id)">
                <div class="row nowrap">
                  <div class="column">
                    <svg-icon class="icon" name="pt-icon-tick" />
                  </div>
                  <div class="column">{{ t.accept }}</div>
                </div>
              </button>
            </div>
            <div class="column" style="margin-left: 16px">
              <button class="ac secondary" @click.stop="reject_step(r.id, r.logs.v().last().id)">
                <div class="row nowrap">
                  <div class="column">
                    <svg-icon class="icon" name="pt-icon-cross" />
                  </div>
                  <div class="column">{{ t.reject }}</div>
                </div>
              </button>
            </div>
          </div>
          <div class="row nowrap between" style="margin-bottom: 0">
            <div class="column" style="margin-right: 16px">
              <div class="row">
                <div class="column">
                  <svg-icon class="icon" name="pt-icon-arrow-right" />
                </div>
                <div class="column">
                  <router-link class="link details" :to="r.link">{{ t.details }}</router-link>
                </div>
              </div>
            </div>
            <div class="column">
              <div class="row" v-show="r.id !== selectedRunId">
                <div class="column">
                  <svg-icon class="icon" name="ic_remove_red_eye" />
                </div>
                <div class="column">
                  <a class="link" @click.stop="showPreview(r)">{{ t.show_preview }}</a>
                </div>
              </div>
            </div>
          </div>
          <div class="row expand" v-if="isAdmin() && !isAssignedToMe(r)">
            <div class="column expand admin-alert">
              <div>{{ t.view_action_admin1 }}</div>
              <div>{{ t.view_action_admin2 }} {{ stepEmail(r, lastStep(r)) }}.</div>
            </div>
          </div>
        </div>
      </div>
      <div class="column expand">
        <embed class="preview" v-if="selectedPdfSrc" type="text/html" :src="selectedPdfSrc" />
        <div v-else style="text-align: center">{{ t.no_preview }}</div>
      </div>
    </div>
    <div class="row top" v-else style="padding: 8px; flex-grow: 1000">
      <div class="column">{{ t.no_actions_filtered }}</div>
    </div>
  </template>
</template>

<script>
import { computed } from 'vue'
import { useProduction } from '../composables/useProduction'
import { useShares } from '../composables/useShares'
import { useProgress } from '../composables/useProgress'
import { getBuilderPreviewUrl } from '../utils/getBuilderPreviewUrl'

export const additions = { icon: 'pt-icon-check' }

export default {
  setup() {
    const { production } = useProduction()
    const { shares, fund_context, loaded: sLoaded } = useShares(false, false, $root.config.extra_share_characteristics)
    const { progress } = useProgress([sLoaded])

    // Initial loading
    const isLoading = computed(() => progress.value !== 1)
    return {
      production,
      isLoading,
      progress,
      shares,
      fund_context,
    }
  },
  data() {
    return {
      filterRunsByEmail: !this.isAdmin(), // true for "normal" users
      currentPeriodDomain: null,
      checkedTagTypes: {
        user_input: true,
        user_validation: true,
      },
      selectedRunId: null,
      selectedPdfSrc: null,
    }
  },
  methods: {
    isAdmin() {
      return $root?.profile && $root?.profile?.role === 'admin'
    },
    onToggleAdmin() {
      this.filterRunsByEmail = !this.filterRunsByEmail
    },
    showPreview(run) {
      this.selectedRunId = run.id
      this.selectedPdfSrc = this.previewUrl(run)
    },
    previewUrl(run) {
      const url = getBuilderPreviewUrl({
        isDataReport: !!run.dataReportId,
        run: { ...run.context, id: run.id, fundId: run.context.fund_id, shareId: run.context.share_id },
      })

      return url
    },
  },
  computed: {
    stepEmail() {
      return (run, step) => {
        const {
          context: { share_id },
        } = run
        let email = step.user
        if (!email) {
          // no email on the step: take the run owner:
          return run.context.owner
        }
        if (email.indexOf('@') > -1) {
          // the email is a true email (i.e. not an alias):
          return email
        }
        // the email is an alias, search for it in the userflows:
        email = (this.fund_context?.share_id && this.fund_context?.share_id[email]) || email
        if (Array.isArray(email)) email = email.join(', ')
        return email
      }
    },
    isAssignedToMe() {
      return run => {
        const theLastStep = this.lastStep(run)
        const email = this.stepEmail(run, theLastStep)
        return !email || [$root?.profile?.email, $root?.profile?.allowed_mailing_list || []].flat().includes(email)
      }
    },
    filteredProduction() {
      // NOTE: this computed has been copied from slash.js ==> need to make it common in the useProduction composable
      let production = this.production.v()
      if (!$root.query.scheduled)
        production = production
          .group(d =>
            ['template', 'isin', 'language', 'domain', 'workflow']
              .map(c => (typeof d[c] === 'object' ? ['wid', d[c].id].join('-') : d[c]))
              .join('-'),
          )
          .map(v => v.last())
          .v()
      if ($root.query.scheduled === 'assignee')
        production = production.filter(r => r.workflow.actions[r.logs.last().action_id].user === $root?.profile?.email)
      if ($root.query.scheduled === 'owner')
        production = production.filter(r => r.context.owner === $root?.profile?.email)
      if ($root.query.scheduled === 'year')
        production = production.filter(r => r.start_date >= new Date().minus('1 year').format())
      if ($root.query.scheduled === 'active')
        production = production.filter(
          r => !(r.workflow.actions.last().id === r.logs.last().action_id && r.logs.last().status === 'success'),
        )

      const filters = Object.entries($root.query)
        .filter(([k, v]) => !['search', 'selected', 'scheduled', 'year', 'action_id'].includes(k))
        .map(([k, v]) => [k, v.split('|')])
      return production.filter(d =>
        filters.every(
          ([k, vs]) =>
            d[k] &&
            vs.some(
              v =>
                `${d[k]}`.replace('.', '') === v ||
                (/^>/.test(v) && d[k] > v.slice(1)) ||
                (/^</.test(v) && d[k] < v.slice(1)),
            ),
        ),
      )
    },
    myRuns() {
      // get all runs for which the current user has something to do
      return this.filteredProduction.filter(run => {
        const theLastStep = this.lastStep(run)
        return (
          ['user_input', 'user_validation'].includes(theLastStep.type) &&
          run.logs.v().last().status === 'running' &&
          !run.disabled &&
          (!this.filterRunsByEmail || this.isAssignedToMe(run))
        )
      })
    },
    myFilteredRuns() {
      // apply the filters selected by the user at the top of the screen
      return this.myRuns.filter(
        run =>
          this.checkedTagTypes[this.lastStep(run).type] &&
          (!this.currentPeriodDomain || this.periodDomain(run) === this.currentPeriodDomain),
      )
    },
    periodDomain() {
      return run => `${run.context.period?.titleize() || ''} ${run.context.domain?.replace(/\|/g, ' | ')}`
    },
    periodDomainValues() {
      return [...new Set(this.myRuns.map(run => this.periodDomain(run)))].sort()
    },
    tagTypeCode() {
      return tagType => ({ run_command: 4, wait_for: 1, user_input: 9, user_validation: 10 })[tagType]
    },
    tagStyle() {
      return code => `background: var(--cat${code});`
    },
    countMyRunsByPeriodDomain() {
      return perDom => this.myRuns.filter(run => !perDom || this.periodDomain(run) === perDom).length
    },
    countMyRunsByTagType() {
      return tagType => this.myRuns.filter(run => this.lastStep(run).type === tagType).length
    },
    lastStep() {
      return run => (!run.logs.v().last().action_id ? {} : run.workflow.actions[run.logs.v().last().action_id])
    },
    lastLog() {
      return run => run.logs.v().last()
    },
  },
}
</script>
