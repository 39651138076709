import { getAssetPublicUrl } from './assetManagement'

export function getHeaderData(context: any, headerDataPath = 'reporting.header') {
  const headerData = headerDataPath.split('.').reduce((acc: any, key: string) => acc?.[key], context.data)
  if (!headerData) {
    throw new Error(`Could not find headerData in data report at path: ${headerDataPath}`)
  }
  return headerData
}

/**
 * Logo image is fetched from stylesheet if it exists, or found in client static assets
 */
export function getLogoImg(context: any) {
  const logoPath = context.stylesheet?.logo

  if (!logoPath) {
    return window.config.logo
  }

  return getAssetPublicUrl({ filenameWithPath: logoPath })
}
