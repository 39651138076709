<template>
  <builder-chart v-bind="{ ...props, nxChartOptions }">
    <template #header="tableProps">
      <tr class="table-header">
        <th class="table-header-cell" v-for="col in tableProps.columns" :key="col">
          <div v-if="col !== tableProps.options.x" v-html="tableProps.formatLabel(col)"></div>
          <div v-else-if="options.tableName" v-html="translate(options.tableName)"></div>
        </th>
      </tr>
    </template>
    <template #default="{ data, columns, options }">
      <tr class="table-row rounded-lg" v-for="(line, idx) in data" :key="idx">
        <td
          class="table-cell overflow-hidden whitespace-nowrap px-2 py-1"
          v-for="col in columns"
          :key="col"
          v-html="options.formatY(line[col])"
        ></td>
      </tr>
      <tr v-if="props?.options?.showTotal" class="table-row rounded-lg">
        <td
          class="table-cell overflow-hidden whitespace-nowrap px-2 py-1"
          v-for="col in columns"
          :key="col"
          v-html="options.formatY(computeTotalRow(data)?.[col])"
        ></td>
      </tr>
    </template>
    <template v-for="(_, name) in $slots" #[name]="slotData">
      <slot :name="name" v-bind="slotData"></slot>
    </template>
  </builder-chart>
</template>

<style scoped></style>

<script setup lang="ts">
import { table as api } from '../composables/builderOptions'
import { bar as story } from './stories'
import { BuilderProps } from '../composables/builderComponent'
import useTranslations from '../composables/translations'
const viz = 'table'

const props = defineProps<BuilderProps>()
const { translate } = useTranslations(props)

function computeTotalRow(data: any[]) {
  return data.reduce(
    (acc, row) => {
      Object.keys(row).forEach(key => {
        if (typeof row[key] === 'number') {
          acc[key] = (acc[key] || 0) + row[key]
        }
      })
      return acc
    },
    { key: translate.value('total') },
  )
}
const nxChartOptions = { viz, lib: 'nx' }
</script>

<script lang="ts">
export default {
  api: {
    ...api,
    tableName: {
      label: 'Table Name',
      default: () => null,
      type: 'translationInput',
    },
    showTotal: {
      label: 'Show Total',
      default: () => false,
      attrs: {
        type: 'checkbox',
        class: 'none',
      },
    },
  },
  styles: {
    '.nx-table .table-header': {
      name: 'Table Header',
      css: `font-weight: bold;
.table-header-cell:first-of-type {
  text-align: left;
}`,
    },
    '.nx-table .table-row': {
      name: 'Table Row',
      css: `.table-cell:first-of-type {
  text-align: left;
}`,
    },
    '.nx-table .table-cell': {
      name: 'Table Cell',
      css: `padding: 4px 8px;
text-align: center;`,
    },
    '.nx-table .table-header-cell': {
      name: 'Table Header Cell',
      css: `padding: 4px 8px;
text-align: center;`,
    },
    '.nx-table.all-table': {
      name: 'Table',
      css: `
.table-row:nth-child(even) {
  background-color: color-mix(in srgb, var(--primary) 10%, transparent);
}`,
    },
  },
  story,
}
</script>
